<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12 required">
        <label for="account_id">Account:</label>
        <Select2 name="account_id" v-model.number="route_target.account_id" :options="accountOptions" required @change="onChangeAccount($event)" @select="onChangeAccount($event)"/>
      </div>
      <div class="form-group col-sm-12 col-md-12 required">
        <label for="route_id">Route:</label>
        <Select2 name="route_id" v-model.number="route_target.route_id" :options="routeOptions" required/>
      </div>
      <div class="form-group col-sm-12 col-md-12 required">
        <label for="year">Year:</label>
        <input class="form-control" type="number" name="year" v-model.number="route_target.year" required/>
      </div>
      <div class="form-group col-sm-12 required">
        <label for="status">Month:</label>
        <select class="form-control" required name="status" v-model.number="route_target.month">          
          <option value="" style="color:black;">Please Select One</option>
          <option value="1" style="color:black;">January</option>
          <option value="2" style="color:black;">February</option>
          <option value="3" style="color:black;">March</option>
          <option value="4" style="color:black;">April</option>
          <option value="5" style="color:black;">May</option>
          <option value="6" style="color:black;">June</option>
          <option value="7" style="color:black;">July</option>
          <option value="8" style="color:black;">August</option>
          <option value="9" style="color:black;">September</option>
          <option value="10" style="color:black;">October</option>
          <option value="11" style="color:black;">November</option>
          <option value="12" style="color:black;">December</option>
        </select>
      </div>
      <div class="form-group col-sm-12 col-md-12 required">
        <label for="target">Target:</label>
        <input class="form-control" type="number" name="target" v-model.number="route_target.target" required/>
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save" :disabled="this.onRequest">
        <router-link class="btn btn-default ml-1" to="/route_targets">Cancel</router-link>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Select2 from 'vue3-select2-component';
  import moment from 'moment'  
  export default {
    computed: {
        ...mapState('accounts', ['accounts']),
        ...mapState('routes', ['routes']),
        ...mapState('route_targets', ['onRequest']),
    },
    data(){
      return {
        route_target: {
          account_id: null,
          route_id: null,
          year: moment().format("YYYY"),
          month: moment().format("M"),
          target: null,
        },
        accountOptions: [],        
        routeOptions: [],
      }
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        const {route_target} = this
        route_target.month = parseInt(route_target.month)
        route_target.year = parseInt(route_target.year)
        this.add(route_target)
      },
      ...mapActions('route_targets', ['add']),
      ...mapActions('accounts', ['get_all']),
      ...mapActions('routes', {getRoutes: 'get_route_by_account'}),
      onChangeAccount(event){
        this.getRoutes({account_id: parseInt(event.id)})
        this.route_target.route_id = null
      }
    },
    created() {
      this.$emit('onChildInit', 'Add New Case Incident')
      this.get_all()
    },
    components:{
      Select2
    },
    watch: {
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
          }
          return
        }
      },
      routes(nextState, prevState){
        if(nextState !== prevState){
          this.routeOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.routeOptions.push({id:item.id, text:item.route_name})
            })
          }
          return
        }
      },
    }
  }
</script>
