<template>
  <div class="row">
    <div class="col-sm-12">
      <form @submit.prevent="handleSubmit">
        <div class="row">
          <div class="form-group col-sm-12 col-md-12 required">
            <label for="account_id">Account:</label>
            <Select2 name="account_id" v-model.number="account_id" :options="accountOptions"/>
          </div>          
          <div class="form-group col-sm-12">
            <input class="btn btn-primary" required type="submit" value="Show" :disabled="this.onRequest">
          </div>
        </div>
      </form>
    </div>
    <div class="col-sm-12">
      <Datatable v-bind:entries="routes" :columns="columns" :title="'Routes'"/>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Datatable from '../components/Datatable.vue'
  import { getCapability } from '../_helpers'
  import Select2 from 'vue3-select2-component';
  export default {
    computed: {
      ...mapState('routes', ['routes', 'onRequest']),
      ...mapState('accounts', ['accounts']),
      ...mapState('users', ['user']),
      columns(){
        return [
          {name: 'route_name', text: 'Route Name'},
          {name: 'route_code', text: 'Route Code'},
          {name: 'route_start', text: 'Start',
            raw: {
              type: 'Sub',
              value: 'stop_name'
            }
          },
          {name: 'route_end', text: 'End',
            raw: {
              type: 'Sub',
              value: 'stop_name'
            }
          },
          {name: 'route_distance', text: 'Distance (km)', class: 'text-right',
            raw: {
                type: 'format_number'
            }
          },
          {name: 'route_color', text: 'Route Color',
            raw: {
              type: 'Color',
            }
          },
          {name: 'route_direction', text: 'Direction',
            raw: {
              type: 'Text',
              fields: [
                {value: '1', text: 'One Way'},
                {value: '2', text: 'Two Ways'},
                {value: '3', text: 'Sub Routes'},
              ]
            }
          },
          {name: 'status', text: 'Status',
            raw: {
              type: 'Text',
              fields: [
                {value: '1', text: 'Active'},
                {value: '2', text: 'Inactive'},
              ]
            }
          },
          {name: 'action', text: 'Action',
            raw: {
              type: 'Action',
              fields: [
                getCapability('edit_route', this.user) ? {event: 'edit_route', text: 'Edit', method: 'get', class: 'btn btn-primary ml-1'} : {},
                getCapability('edit_route', this.user) ? {event: 'route_vehicles', text: 'Assign Vehicles', method: 'get', class: 'btn btn-success ml-1'} : {},
                getCapability('edit_route', this.user) ? {event: 'route_stops', text: 'Edit Stops', method: 'get', class: 'btn btn-warning ml-1'} : {},
                getCapability('edit_route', this.user) ? {event: 'route_draws', text: 'Draw Route', method: 'get', class: 'btn btn-secondary ml-1'} : {},
                getCapability('delete_route', this.user) ? {event: 'routes/destroy', text: 'Delete', method: 'post', class: 'btn btn-danger ml-1'} : {},
              ]
            }
          },
        ]
      }
    },
    data(){
      return {
        account_id: null,
        accountOptions: [],
      }
    },
    components:{
      Datatable,
      Select2,
    },
    methods: {
      ...mapActions('routes', ['get_route_by_account']),
      ...mapActions('accounts', ['get_all']),
      ...mapActions('alert', ['error', 'clear']),
      handleSubmit(){
        if(this.onRequest) return;
        this.clear()
        this.get_route_by_account({account_id: this.account_id})
      },
    },
    created() {
      this.$emit('onChildInit', 'Routes', getCapability('add_route', this.user) ? '/routes/add' : '')
      this.get_all()
    },
    watch: {
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
            if(this.accountOptions.length > 0){
              this.account_id = this.accountOptions[0].id
              this.handleSubmit()
            }
          }
          return
        }
      },
    }
  }
</script>
