<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12 col-md-12 required">
          <label for="parent_id">Account:</label>
          <Select2 name="account_id" v-model.number="route.account_id" :options="accountOptions"  @change="onChange($event)" @select="onChange($event)"/>
      </div>
      <div class="form-group col-sm-12 col-md-6 required">
          <label for="route_name">Route Name:</label>
          <input class="form-control" required="" name="route_name" type="text" v-model="route.route_name">
      </div>
      <div class="form-group col-sm-12 col-md-6 required">
        <label for="route_code">Route Code:</label>
        <input class="form-control" required="" name="route_code" type="text" v-model="route.route_code">
      </div>
      <div class="form-group col-sm-12 col-md-12 required">
        <label for="route_start">Location Start:</label>
        <Select2 name="route_start_id" v-model.number="route.route_start_id" :options="stopOptions" />
      </div>
      <div class="form-group col-sm-12 col-md-12 required">
        <label for="route_end">Location End:</label>
        <Select2 name="route_end_id" v-model.number="route.route_end_id" :options="stopOptions" />
      </div>
      <div class="form-group col-sm-12 col-md-6 required">
        <label for="route_distance">Distance (km):</label>
        <input class="form-control" required="" name="route_distance" type="number" step="any" min="0" v-model.number="route.route_distance">
      </div>
      <div class="form-group col-sm-12 col-md-6 required">
        <label for="route_direction">Direction:</label>
        <select class="form-control" name="route_direction" v-model.number="route.route_direction">
          <option value="1" style="color:black;">One Way</option>
          <option value="2" style="color:black;">Two Ways</option>
          <option value="3" style="color:black;" v-if="getCapabilityAble('sub_routes')">Sub Routes</option>
        </select>
      </div>
      <div class="form-group col-sm-12 col-md-6 required">
        <label for="route_direction">Route Color:</label>
        <input class="form-control" required="" type="color" name="route_color" v-model="route.route_color">
      </div>
      <div class="form-group col-sm-12 col-md-6 required">
        <label for="status">Status:</label>
        <select class="form-control" name="status" v-model.number="route.status">
          <option value="1" style="color:black;">Active</option>
          <option value="2" style="color:black;">Inactive</option>
        </select>
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
        <router-link class="btn btn-default ml-1" to="/routes">Cancel</router-link>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import { getCapability } from '../_helpers'
  import Select2 from 'vue3-select2-component';
  export default {
    computed: {
        ...mapState('accounts', ['accounts']),
        ...mapState('routes', ['onRequest']),
        ...mapState('stops', ['stops']),
        ...mapState('users', ['user'])
    },
    data(){
      return {
        route: {
          account_id: '',
          route_name: '',
          route_code: '',
          route_start_id: '',
          route_end_id: '',
          route_distance: '',
          route_direction: 1,
          status: 1,
        },
        stopOptions: [],
        accountOptions: [],
      }
    },
    components:{
      Select2
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        const {route} = this
        this.add(route)
      },
      ...mapActions('routes', ['add']),
      ...mapActions('accounts', ['get_all']),
      ...mapActions('stops', {getStops: 'get_stop_by_account'}),
      onChange(event){
        this.getStops({account_id: parseInt(event.id)})
        this.route.route_start_id = ''
        this.route.route_end_id = ''
      },
      getCapabilityAble(action){
        return getCapability(action, this.user)
      }
    },
    created() {
      this.$emit('onChildInit', 'Add New Route')
      this.get_all()
    },
    watch: {
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
          }
          return
        }
      },
      stops(nextState, prevState){
        if(nextState !== prevState){
          this.stopOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.stopOptions.push({id:item.id, text:item.stop_name})
            })
          }
          return
        }
      }
    }
  }
</script>
