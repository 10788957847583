<template>
    <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12 col-lg-6">
          <label for="parent_id">Account:</label>
          <input class="form-control" v-if="route.account" disabled name="route_name" type="text" v-model="route.account.account_name">
      </div>
      <div class="form-group col-sm-12 col-lg-6">
          <label for="route_name">Route Name:</label>
          <input class="form-control" disabled name="route_name" type="text" v-model="route.route_name">
      </div>
      <div class="form-group col-sm-12 col-lg-6">
        <label for="route_code">Route Code:</label>
        <input class="form-control" disabled name="route_code" type="text" v-model="route.route_code">
      </div>
      <div class="form-group col-sm-12 col-lg-6">
        <label for="route_distance">Distance (km):</label>
        <input class="form-control" disabled name="route_distance" type="number" step="any" min="0" v-model.number="route.route_distance">
      </div>
      <div v-if="route.route_start" class="form-group col-sm-12 col-lg-6">
        <label for="route_start">Start:</label>
        <input class="form-control" disabled name="route_start" type="text" v-model="route.route_start.stop_name">
      </div>
      <div v-if="route.route_end" class="form-group col-sm-12 col-lg-6">
        <label for="route_end">End:</label>
        <input class="form-control" disabled name="route_end" type="text" v-model="route.route_end.stop_name">
      </div>
      <div class="form-group col-sm-12 col-lg-6">
        <label for="route_direction">Direction:</label>
        <input class="form-control" disabled v-if="route.route_direction == 1" name="route_end" type="text" value="One Way">
        <input class="form-control" disabled v-if="route.route_direction == 2" name="route_end" type="text" value="Two Ways">
        <input class="form-control" disabled v-if="route.route_direction == 3" name="route_end" type="text" value="Sub Routes">
      </div>
      <div class="form-group col-sm-12 col-lg-6">
        <label for="route_direction">Route Color:</label>
        <input class="form-control" disabled type="color" name="route_color" v-model="route.route_color">
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <div class="card">
          <div class="card-body">
            <h5 class="text-center text-bold">AVAILABLE <span class="btn btn-success float-right mb-3" @click.prevent="addAllItem()"><i class="fa fa-plus"> All</i></span></h5>
            <table class="table">
              <tr>
                <th>Vehicle No</th>
                <th>Vehicle Code</th>
                <th>Action</th>
              </tr>
              <tr v-for="(vehicle, index) in vehicles" :key="index">
                <td>{{vehicle.vehicle_no}}</td>
                <td>{{vehicle.vehicle_code}}</td>
                <td>
                  <span class="btn btn-success mr-1" @click.prevent="addItem(index)"><i class="fa fa-plus"></i></span>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="card">
          <div class="card-body">
            <h5 class="text-bold text-center">ASSIGNED <span class="btn btn-danger float-left mb-3" @click.prevent="removeAllItem()"><i class="fa fa-trash"> All</i></span></h5>
            <table class="table">
              <tr>
                <th>Vehicle No</th>
                <th>Vehicle Code</th>
                <th>Action</th>
              </tr>
              <tr v-for="(vehicle, index) in route_vehicles" :key="index">
                <td>{{vehicle.vehicle_no}}</td>
                <td>{{vehicle.vehicle_code}}</td>
                <td>
                  <span class="btn btn-danger mr-1" @click.prevent="removeItem(index)"><i class="fa fa-trash"></i></span>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
        <router-link class="btn btn-default ml-1" to="/routes">Cancel</router-link>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  export default {
    computed: {
        ...mapState('routes', ['onRequest', 'route']),
        ...mapState('vehicles', ['vehicles']),
    },
    data() {
      return {
        availables: [],
        route_vehicles: [],
      }
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        let route_vehicles = []
        this.route_vehicles.map(item => {
          route_vehicles.push({vehicle_id: item.id})
        })
        let data = {
          id: this.route.id,
          route_vehicles: route_vehicles
        }
        this.edit_route_vehicles(data)
      },
      addItem(index){
        let item = this.vehicles[index]
        this.vehicles.splice(index, 1)
        this.route_vehicles.push(item)
      },
      removeItem(index){
        let item = this.route_vehicles[index]
        this.route_vehicles.splice(index, 1)
        this.vehicles.push(item)
      },
      addAllItem(){
        this.vehicles.map(item => {
          this.route_vehicles.push(item)
        })
        this.vehicles.splice(0, this.vehicles.length)
      },
      removeAllItem(){
        this.route_vehicles.map(item => {
          this.vehicles.push(item)
        })
        this.route_vehicles.splice(0, this.route_vehicles.length)
      },
      ...mapActions('routes', ['edit_route_vehicles', 'get']),
      ...mapActions('vehicles', {getAvailableVehicles: 'get_available_vehicles'}),
    },
    created() {
      this.$emit('onChildInit', 'Assign Vehicles')
      this.get(this.$route.params.id)
    },
    watch: {
      route (nextState, prevState) {
        if(nextState !== prevState){
          if(nextState.account_id){
            this.getAvailableVehicles({account_id: nextState.account_id})
          }
          if(nextState.route_vehicles){
            nextState.route_vehicles.map(item => {
              this.route_vehicles.push(item.vehicle)
            })
          }
          return
        }
      },
    },
  }
</script>
